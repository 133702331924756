import { render, staticRenderFns } from "./StorySlider.vue?vue&type=template&id=6bdd7d46"
import script from "./StorySlider.vue?vue&type=script&lang=js"
export * from "./StorySlider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCardStory: require('/src/components/molecules/card/Story.vue').default,MoleculesSlider: require('/src/components/molecules/Slider.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,AtomsModuleWrapper: require('/src/components/atoms/ModuleWrapper.vue').default})
