
import {
  arrayOf,
  nullable,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { categoryShape, entryShape, imageShape } from '~/constants/PropTypes';
import query from '~/gql/queries/components/categoryStories.gql';

export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    subheading: oneOfType([string(), nullable()]),
    category: arrayOf(shape(categoryShape).loose),
    stories: arrayOf(
      shape({
        ...entryShape,
        authorEntry: arrayOf(object()),
        postDate: oneOfType([string(), nullable()]),
        readTime: number(),
        category: arrayOf(shape(categoryShape).loose),
        visual: arrayOf(shape(imageShape).loose),
      }).loose
    ),
  },
  data() {
    return {
      toRenderStories: [],
    };
  },
  async fetch() {
    if (this.stories && this.stories.length) {
      this.toRenderStories = this.stories;
      return;
    }

    const result = await this.$cms.query({
      query,
      variables: {
        site: this.$i18n.locale,
        categoryId: this.$first(this.category)?.id,
        limit: 9,
      },
    });
    this.toRenderStories = result?.stories || [];
  },
};
